import { IconBaseProps } from "react-icons";
import { BiCommentAdd, BiLoaderAlt } from "react-icons/bi";
import { FaBirthdayCake, FaLongArrowAltRight, FaRegFolder, FaRegFolderOpen } from "react-icons/fa";
import { GiPartyPopper } from "react-icons/gi";

import {
  IoIosArrowBack,
  IoIosArrowForward,
  IoIosCamera,
  IoIosMore,
  IoIosRefresh,
  IoLogoEuro,
  IoMdAdd,
  IoMdAlert,
  IoMdArrowDropdown,
  IoMdArrowDropleft,
  IoMdArrowDropright,
  IoMdArrowDropup,
  IoMdArrowRoundBack,
  IoMdArrowRoundForward,
  IoMdBriefcase,
  IoMdCall,
  IoMdCar,
  IoMdChatbubbles,
  IoMdCheckmark,
  IoMdCheckmarkCircle,
  IoMdClose,
  IoMdCloseCircle,
  IoMdCloudUpload,
  IoMdColorWand,
  IoMdCopy,
  IoMdCreate,
  IoMdDocument,
  IoMdDownload,
  IoMdEye,
  IoMdHome,
  IoMdInformationCircle,
  IoMdInformationCircleOutline,
  IoMdLink,
  IoMdListBox,
  IoMdMail,
  IoMdMoon,
  IoMdNotifications,
  IoMdNotificationsOff,
  IoMdNotificationsOutline,
  IoMdOpen,
  IoMdPerson,
  IoMdPin,
  IoMdRecording,
  IoMdRemove,
  IoMdRepeat,
  IoMdReturnRight,
  IoMdSad,
  IoMdSearch,
  IoMdStar,
  IoMdStats,
  IoMdSunny,
  IoMdTrain,
  IoMdTrash,
  IoMdTrendingDown,
  IoMdTrendingUp,
  IoMdUndo,
  IoMdUnlock,
  IoMdWalk,
  IoMdWarning,
} from "react-icons/io";
import { IoWarningOutline } from "react-icons/io5";

import {
  MdAccessTime,
  MdBlock,
  MdBlockFlipped,
  MdCallMissed,
  MdCheckCircle,
  MdCheckCircleOutline,
  MdChecklist,
  MdContentCopy,
  MdContentPaste,
  MdContentPasteSearch,
  MdDeleteOutline,
  MdDraw,
  MdEdit,
  MdElderly,
  MdError,
  MdFavorite,
  MdFavoriteBorder,
  MdFindReplace,
  MdFormatBold,
  MdFormatItalic,
  MdFormatListBulleted,
  MdFormatListNumbered,
  MdFormatStrikethrough,
  MdFormatUnderlined,
  MdHandshake,
  MdImportExport,
  MdInfoOutline,
  MdKeyboardBackspace,
  MdListAlt,
  MdMarkChatUnread,
  MdMessage,
  MdOpenInNew,
  MdOutlineAccountCircle,
  MdOutlineArchive,
  MdOutlineCalendarToday,
  MdOutlineCameraAlt,
  MdOutlineCancel,
  MdOutlineChevronRight,
  MdOutlineDangerous,
  MdOutlineDocumentScanner,
  MdOutlineErrorOutline,
  MdOutlineEvent,
  MdOutlineEventAvailable,
  MdOutlineEventBusy,
  MdOutlineFactCheck,
  MdOutlineFeed,
  MdOutlineFileUpload,
  MdOutlineFolderShared,
  MdOutlineFollowTheSigns,
  MdOutlineForest,
  MdOutlineHomeWork,
  MdOutlineLocationOff,
  MdOutlineMail,
  MdOutlineMedicalInformation,
  MdOutlineMessage,
  MdOutlineMonitorHeart,
  MdOutlineNewReleases,
  MdOutlineNoPhotography,
  MdOutlineNotListedLocation,
  MdOutlineOfflineBolt,
  MdOutlinePending,
  MdOutlinePeopleAlt,
  MdOutlinePerson,
  MdOutlinePersonOff,
  MdOutlinePersonPin,
  MdOutlinePhoneDisabled,
  MdOutlinePinDrop,
  MdOutlineReceipt,
  MdOutlineSavings,
  MdOutlineSchedule,
  MdOutlineSchool,
  MdOutlineSettings,
  MdOutlineShoppingBasket,
  MdOutlineSupportAgent,
  MdOutlineTask,
  MdOutlineVisibility,
  MdOutlineVisibilityOff,
  MdOutlineVolunteerActivism,
  MdOutlineWatchLater,
  MdPeopleAlt,
  MdPersonRemove,
  MdPersonSearch,
  MdPhoneMissed,
  MdRotateRight,
  MdSavings,
  MdSettings,
  MdSpeakerNotesOff,
  MdOutlineAssignment,
  MdOutlineAppShortcut,
  MdOutlineThumbUp,
  MdOutlineThumbDown,
  MdOutlineSentimentSatisfied,
  MdOutlineSentimentDissatisfied,
  MdAutoAwesome,
  MdOutlineSentimentNeutral,
  MdOutlineReplay,
  MdMoreTime,
  MdArrowBack,
  MdArrowForward,
  MdOutlineCottage,
  MdDiversity1,
} from "react-icons/md";

// This class maps the imported icons with their name inside Hassibot.
export const IconsMapping = {
  formatBold: MdFormatBold,
  formatItalic: MdFormatItalic,
  formatStrikethrough: MdFormatStrikethrough,
  formatUnderline: MdFormatUnderlined,
  bulletList: MdFormatListBulleted,
  numberList: MdFormatListNumbered,
  checkList: MdChecklist,
  spinner: BiLoaderAlt,
  outlineCancel: MdOutlineCancel,
  keyboardBackspace: MdKeyboardBackspace,
  arrowDropup: IoMdArrowDropup,
  arrowDropdown: IoMdArrowDropdown,
  chevronRight: IoIosArrowForward,
  chevronLeft: IoIosArrowBack,
  checkmark: IoMdCheckmark,
  close: IoMdClose,
  call: IoMdCall,
  arrowRoundForward: IoMdArrowRoundForward,
  arrowRoundBackward: IoMdArrowRoundBack,
  mail: IoMdMail,
  mailOutline: MdOutlineMail,
  calendar: MdOutlineEvent,
  calendarChecked: MdOutlineEventAvailable,
  calendarOutline: MdOutlineCalendarToday,
  listBox: IoMdListBox,
  returnRight: IoMdReturnRight,
  arrowDropRight: IoMdArrowDropright,
  repeat: IoMdRepeat,
  cloudUpload: IoMdCloudUpload,
  copy: IoMdCopy,
  home: IoMdHome,
  briefcase: IoMdBriefcase,
  notifications: IoMdNotifications,
  notificationsOutline: IoMdNotificationsOutline,
  train: IoMdTrain,
  car: IoMdCar,
  trendingUp: IoMdTrendingUp,
  trendingDown: IoMdTrendingDown,
  refresh: IoIosRefresh,
  more: IoIosMore,
  link: IoMdLink,
  search: IoMdSearch,
  create: IoMdCreate,
  add: IoMdAdd,
  pin: IoMdPin,
  pinDropOutline: MdOutlinePinDrop,
  noPinOutline: MdOutlineLocationOff,
  chatBubbles: IoMdChatbubbles,
  undo: IoMdUndo,
  closeCircle: IoMdCloseCircle,
  arrowDropLeft: IoMdArrowDropleft,
  notificationsOff: IoMdNotificationsOff,
  unlock: IoMdUnlock,
  trash: IoMdTrash,
  trashOutline: MdDeleteOutline,
  alert: IoMdAlert,
  document: IoMdDocument,
  remove: IoMdRemove,
  walk: IoMdWalk,
  time: MdAccessTime,
  moreTime: MdMoreTime,
  sunny: IoMdSunny,
  moon: IoMdMoon,
  colorWand: IoMdColorWand,
  sad: IoMdSad,
  download: IoMdDownload,
  info: IoMdInformationCircle,
  infoOutline: MdInfoOutline,
  person: IoMdPerson,
  people: MdPeopleAlt,
  camera: IoIosCamera,
  stats: IoMdStats,
  leaveHb: IoMdOpen,
  warning: IoMdWarning,
  warningOutline: IoWarningOutline,
  warningCircleOutline: MdError,
  eye: IoMdEye,
  euro: IoLogoEuro,
  success: IoMdCheckmarkCircle,
  error: IoMdCloseCircle,
  outlineError: MdOutlineErrorOutline,
  forbidden: MdBlockFlipped,
  block: MdBlock,
  personOff: MdOutlinePersonOff,
  personOutline: MdOutlinePerson,
  personPinOutline: MdOutlinePersonPin,
  persoSearch: MdPersonSearch,
  archive: MdOutlineArchive,
  star: IoMdStar,
  partyPopper: GiPartyPopper,
  receiptOutline: MdOutlineReceipt,
  checkCircleOutline: MdCheckCircleOutline,
  checkCircle: MdCheckCircle,
  notListedLocationOutline: MdOutlineNotListedLocation,
  personCircleOutline: MdOutlineAccountCircle,
  personRemove: MdPersonRemove,
  phoneDisabled: MdOutlinePhoneDisabled,
  watchLater: MdOutlineWatchLater,
  export: MdImportExport,
  forestOutline: MdOutlineForest,
  commentAdd: BiCommentAdd,
  shoppingBasket: MdOutlineShoppingBasket,
  homeWork: MdOutlineHomeWork,
  favorite: MdFavorite,
  favoriteOutline: MdFavoriteBorder,
  copyContent: MdContentCopy,
  savings: MdSavings,
  savingsOutline: MdOutlineSavings,
  donation: MdOutlineVolunteerActivism,
  taskCheck: MdOutlineTask,
  scannerOutline: MdOutlineDocumentScanner,
  cameraOutline: MdOutlineCameraAlt,
  noPhotoOutline: MdOutlineNoPhotography,
  visibilityOff: MdOutlineVisibilityOff,
  visibility: MdOutlineVisibility,
  outlinedPeopleAlt: MdOutlinePeopleAlt,
  outlinedFindReplace: MdFindReplace,
  outlinedFactCheck: MdOutlineFactCheck,
  outlinedFollowTheSigns: MdOutlineFollowTheSigns,
  outlinedSupportAgent: MdOutlineSupportAgent,
  outlineMonitorHeart: MdOutlineMonitorHeart,
  boltOutline: MdOutlineOfflineBolt,
  contact: MdOutlineFeed,
  outlinedSchedule: MdOutlineSchedule,
  arrowAltRight: FaLongArrowAltRight,
  pasteSearchContent: MdContentPasteSearch,
  folderOpen: FaRegFolderOpen,
  folder: FaRegFolder,
  outlinedSettings: MdOutlineSettings,
  medicalInformation: MdOutlineMedicalInformation,
  contentPasteOutline: MdContentPaste,
  pencil: MdEdit,
  school: MdOutlineSchool,
  outlineFolderShared: MdOutlineFolderShared,
  outlinedInfo: IoMdInformationCircleOutline,
  outlineChevronRight: MdOutlineChevronRight,
  listAlt: MdListAlt,
  openInNew: MdOpenInNew,
  outlinePending: MdOutlinePending,
  callMissed: MdCallMissed,
  eventBusyOutline: MdOutlineEventBusy,
  newReleaseOutline: MdOutlineNewReleases,
  missedCall: MdPhoneMissed,
  recordedMessage: IoMdRecording,
  rotateRight: MdRotateRight,
  draw: MdDraw,
  markChatUnread: MdMarkChatUnread,
  chatOff: MdSpeakerNotesOff,
  chatOn: MdMessage,
  handshake: MdHandshake,
  dangerousOutline: MdOutlineDangerous,
  settings: MdSettings,
  message: MdOutlineMessage,
  elderly: MdElderly,
  upload: MdOutlineFileUpload,
  appShortcut: MdOutlineAppShortcut,
  happyFace: MdOutlineSentimentSatisfied,
  sadFace: MdOutlineSentimentDissatisfied,
  neutralFace: MdOutlineSentimentNeutral,
  aiStars: MdAutoAwesome,
  thumbUp: MdOutlineThumbUp,
  thumbDown: MdOutlineThumbDown,
  assignment: MdOutlineAssignment,
  replay: MdOutlineReplay,
  arrowLeft: MdArrowBack,
  arrowRight: MdArrowForward,
  birthdayCake: FaBirthdayCake,
  cottage: MdOutlineCottage,
  diversity: MdDiversity1,
};

export type IconName = keyof typeof IconsMapping;

type SimpleIcon = {
  name: IconName;
};
export type IconProps = SimpleIcon & IconBaseProps;

export const Icon = (props: IconProps) => {
  const { name, ...reactIconsProps } = props;
  const CorrespondingIcon = IconsMapping[name];
  return CorrespondingIcon ? <CorrespondingIcon {...reactIconsProps} /> : null;
};
